import Text from 'components/commons/text';
import { styled } from 'styles';
import typography from 'styles/typography';

export const ErrorMessage = styled(Text, {
  ...typography.body3,
  marginTop: 4,
  color: '$error',
  svg: {
    marginRight: 8,
  },
});

export const Hint = styled('span', {
  ...typography.body4,
  color: '$info',
  display: 'flex',
  alignItems: 'center',
  marginTop: 4,
  svg: {
    marginRight: 8,
  },
  variants: {
    variant: {
      focus: {},
      error: {},
      success: {
        color: '$success',
      },
      disabled: {},
      default: {
        color: '$info',
      },
    },
  },
});
