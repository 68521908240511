import * as React from 'react';

export const RadioContext = React.createContext<any>(null);

export function useRadioProvider() {
  const context = React.useContext(RadioContext);
  if (context === undefined) {
    throw new Error('useRadioProvider must be used within RadioProvider');
  }
  return context;
}
