import getFieldError from 'common/helpers/get-field-error';
import Stack from 'components/commons/stack';
import { IStepperProps } from 'components/widgets/stepper';
import Stepper from 'components/widgets/stepper/stepper';
import isEmpty from 'lodash/isEmpty';
import MultipleSelect, {
  IMultipleSelectProps,
} from 'modules/shipping/multiple-select';
import * as React from 'react';
import { AlertTriangle, CheckCircle, Info } from 'react-feather';
import { useController, useFormContext } from 'react-hook-form';
import { useIMask } from 'react-imask';
import PinInput from 'react-pin-input';
import { ActionMeta } from 'react-select';
import color from 'styles/color';
import typography from 'styles/typography';

import Button, { IButtonProps } from './button';
import Checkbox, { ICheckboxProps } from './checkbox/checkbox';
import DatePicker, { IDatePickerProps } from './date-picker';
import DropdownSelect from './dropdown';
import { MultipleFilePicker, MultipleFilePickerProps } from './file-picker';
import { FormContext } from './form';
import Label from './label';
import { Radio } from './radio/main/radio';
import { RadioGroup } from './radio/main/radio-group';
import Select, { ISelectProps } from './select';
import SelectCreateable from './select-createable';
import StepperSelect, { IStepperSelectProps } from './stepper-select';
import { ErrorMessage, Hint } from './style';
import TextField, { ITextFieldProps } from './text-field';
import TextareaField, { ITextareaFieldProps } from './textarea-field';

type InputType =
  | 'button'
  | 'text'
  | 'masked-text'
  | 'textarea'
  | 'tel'
  | 'phone'
  | 'otp'
  | 'email'
  | 'password'
  | 'select'
  | 'number'
  | 'checkbox'
  | 'dropdown'
  | 'radio-group'
  | 'files'
  | 'date'
  | 'stepper'
  | 'stepper-select'
  | 'multiple-select'
  | 'dropdown-select'
  | 'select-createable'
  | 'submit';

export interface InputProps {
  type: InputType;
  name: string;
}

interface PinInputProps {
  length: number;
  initialValue?: number | string;
  type?: InputType;
  inputMode?: string;
  secret?: boolean;
  disabled?: boolean;
  focus?: boolean;
  onChange?: (value: string, index: number) => void;
  onComplete?: (value: string, index: number) => void;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  inputFocusStyle?: React.CSSProperties;
  validate?: (value: string) => string;
  autoSelect?: boolean;
  regexCriteria?: any;
}

interface TextInputProps extends InputProps, Omit<ITextFieldProps, 'name'> {
  type: 'text' | 'password' | 'number' | 'email' | 'tel';
  onTextAfterChange?: () => void;
}

interface MaskedInputProps extends InputProps, Omit<ITextFieldProps, 'name'> {
  type: 'masked-text';
  mask: string;
  lazy?: boolean;
  placeholderChar?: string;
}

interface PhoneTextInputProps
  extends InputProps,
    Omit<ITextFieldProps, 'name'> {
  type: 'phone';
  phoneName: string;
}

interface TextareaInputProps
  extends InputProps,
    Omit<ITextareaFieldProps, 'name'> {
  type: 'textarea';
}

interface CheckBoxProps
  extends InputProps,
    Omit<ICheckboxProps, 'name' | 'value' | 'type' | 'isSelected'> {
  type: 'checkbox';
}

interface RadioGroupProps extends InputProps {
  type: 'radio-group';
  label?: string;
  orientation?: 'vertical' | 'horizontal';
  items: { key: string; text: string; isDisabled?: boolean }[];
  disabled?: boolean;
}

interface SubmitProps extends IButtonProps {
  type: 'submit' | 'button';
  text?: string;
}

interface OTPProps
  extends InputProps,
    Omit<PinInputProps, 'name' | 'onChange' | 'value' | 'type'> {
  type: 'otp';
  name: string;
}

interface DatePickerProps
  extends InputProps,
    Omit<IDatePickerProps, 'name' | 'onChange' | 'value'> {
  value?: Date;
  type: 'date';
  placeholder?: string;
  minDate?: Date;
}

interface SelectProps
  extends InputProps,
    Omit<ISelectProps, 'onChange' | 'onBlur' | 'name'> {
  type: 'select';
  label?: string;
  required?: boolean;
  hint?: string;
  onChange?: (prevValue?: string | null, newValue?: string | null) => void;
  noDataMessage?: string;
  onAfterChange?: (newValue: any) => void;
}

interface SelectCreateableProps extends Omit<SelectProps, 'type'> {
  type: 'select-createable';
  outsideText?: string;
}

interface StepperSelectProps
  extends InputProps,
    Omit<IStepperSelectProps, 'onChange'> {
  type: 'stepper-select';
}

interface DropdownSelectProps
  extends InputProps,
    Omit<IStepperSelectProps, 'onChange'> {
  type: 'dropdown-select';
}

interface MultipleSelectProps
  extends InputProps,
    Omit<IMultipleSelectProps, 'onChange' | 'value'> {
  type: 'multiple-select';
}

interface StepperProps extends InputProps, IStepperProps {
  type: 'stepper';
}

function CheckboxField(props: CheckBoxProps) {
  const context = React.useContext(FormContext);
  const { control, setValue } = useFormContext<any>();
  const { field } = useController({
    control,
    name: props.name,
  });
  const { ...rest } = props;

  const disabled = !context.editable || props.isDisabled;

  return (
    <Checkbox
      {...rest}
      {...field}
      isSelected={field.value}
      onChange={(isSelected) => {
        props.onChange && props.onChange(isSelected);
        setValue(field.name, isSelected, {
          shouldTouch: true,
          shouldValidate: true,
        });
      }}
      isDisabled={disabled}
    />
  );
}

function TextInputField(props: TextInputProps) {
  const { control } = useFormContext<any>();
  const context = React.useContext(FormContext);
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  return (
    <TextField
      {...props}
      {...field}
      value={
        props.type === 'number'
          ? parseFloat(field.value).toString()
          : field.value
      }
      errorMessage={
        fieldState.error ? fieldState.error?.message?.toString()! : undefined
      }
      disabled={!context.editable || props.disabled}
      onXClick={() => {
        field.onChange('');
      }}
    />
  );
}

function MaskedTextInputField(props: MaskedInputProps) {
  const { control, watch, setValue } = useFormContext<any>();
  const context = React.useContext(FormContext);

  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  const { ref, unmaskedValue, maskRef } = useIMask({
    mask: props.mask,
    placeholderChar: props.placeholderChar || '',
    lazy: props.lazy,
    max: props.max || null,
    definitions: {
      '#': /[0-9A-Za-z]/,
      '@': /[0-9+-]/,
    },
  });
  React.useEffect(() => {
    if (ref.current?.value) {
      maskRef.current.value = watch(props.name);
      maskRef.current.updateValue();
    }
  }, [watch, props.name, ref, maskRef]);

  React.useEffect(() => {
    setValue(props.name, unmaskedValue, {
      shouldValidate: true,
    });
  }, [props.name, setValue, unmaskedValue]);

  return (
    <TextField
      aria-label={field.name}
      {...props}
      name={field.name}
      ref={ref as React.MutableRefObject<HTMLInputElement>}
      errorMessage={
        fieldState.error ? fieldState.error.message?.toString()! : undefined
      }
      disabled={props.disabled || !context.editable}
      onXClick={() => field.onChange('')}
    />
  );
}

function PhoneTextInputField(props: PhoneTextInputProps) {
  const { control, setValue, watch } = useFormContext<any>();
  const context = React.useContext(FormContext);
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  return (
    <TextField
      {...props}
      {...field}
      phoneCodeProps={{
        onChange: (newValue, actionMeta) => {
          setValue(props.phoneName, newValue.value, {
            shouldValidate: true,
          });
        },
        value: {
          value: watch(props.phoneName),
          label: watch(props.phoneName),
        },
      }}
      errorMessage={
        fieldState.error ? fieldState.error.message?.toString() : undefined
      }
      disabled={props.disabled || !context.editable}
      onXClick={() => field.onChange('')}
    />
  );
}

function TextareaInputField(props: TextareaInputProps) {
  const context = React.useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  return (
    <TextareaField
      {...props}
      {...field}
      errorMessage={
        fieldState.error ? fieldState.error.message?.toString() : undefined
      }
      disabled={props.disabled || !context.editable}
    />
  );
}

function DatePickerInputField(props: DatePickerProps) {
  const { control, setValue, watch } = useFormContext<any>();
  const context = React.useContext(FormContext);
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  React.useEffect(() => {
    if (!watch(props.name)) {
      setValue(props.name, new Date(), {
        shouldValidate: true,
      });
    }
  }, [props.name, setValue, watch]);

  return (
    <DatePicker
      {...props}
      {...field}
      onChange={(value, e) => field.onChange(value)}
      errorMessage={fieldState.error ? fieldState.error.toString() : undefined}
      disabled={props.disabled || !context.editable}
    />
  );
}

function RadioGroupInputField(props: RadioGroupProps) {
  const context = React.useContext(FormContext);
  const { items, orientation = 'vertical', label } = props;
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  const disabled = !context.editable || props.disabled;

  return (
    <Stack>
      <RadioGroup
        name={field.name}
        orientation={orientation}
        value={field.value}
        aria-label={label}
        disabled={disabled}
        onChange={(val) => field.onChange(val)}
      >
        {items.map((item) => (
          <Radio
            style={{ display: 'block' }}
            value={item.key}
            isDisabled={item.isDisabled}
          >
            {item.text}
          </Radio>
        ))}
      </RadioGroup>
      {fieldState.error && (
        <ErrorMessage>
          <AlertTriangle color={color.error} size={16} />
          {fieldState.error.message?.toString()}
        </ErrorMessage>
      )}
    </Stack>
  );
}

function OTPInput(props: OTPProps) {
  const { length, ...rest } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({
    control,
    name: props.name,
  });

  return (
    <PinInput
      {...rest}
      length={length}
      initialValue=""
      type="numeric"
      inputMode="number"
      inputStyle={{
        border: 'none',
        borderBottom: `2px solid ${color.neutral8}`,
        ...typography.headline3,
        marginLeft: 8,
        marginRight: 8,
      }}
      onChange={(value) => field.onChange(value)}
    />
  );
}

function SubmitField(props: SubmitProps) {
  const { disabled, text = 'Submit', ...restProps } = props;
  const { formState } = useFormContext<any>();

  const context = React.useContext(FormContext);

  if (!context.editable && !formState.isSubmitting) {
    return null;
  }

  const isValid = isEmpty(formState.errors);

  return (
    <Button
      {...restProps}
      disabled={disabled || !isValid || formState.isSubmitting}
      isLoading={formState.isSubmitting}
      type="submit"
    >
      {text}
    </Button>
  );
}

function SelectCreateableField(props: SelectCreateableProps) {
  const {
    disabled,
    label,
    required,
    hint,
    placeholder,
    onChange,
    options = [],
    outsideText,
    ...rest
  } = props;
  const { control } = useFormContext<any>();
  const context = React.useContext(FormContext);

  const { field, fieldState } = useController({
    name: props.name,
    control,
  });

  const [state, setState] = React.useState<
    'default' | 'error' | 'focus' | 'success' | 'disabled'
  >('default');

  React.useEffect(() => {
    if (disabled) {
      setState('disabled');
    } else {
      setState('default');
    }
  }, [disabled]);

  React.useEffect(() => {
    if (fieldState.error) {
      setState('error');
    } else {
      setState('default');
    }
  }, [fieldState]);

  const _value =
    (options as any).find((option) => option.label === field.value) || null;

  const [value, setValue] = React.useState<{
    label: string;
    value: string;
    [x: string]: any;
  } | null>(field.value ? _value : null);

  const [inputValue, setInputValue] = React.useState(field.value || '');

  const onChangeItem: (newValue: any, actionMeta: ActionMeta<any>) => void = (
    newValue,
    { action },
  ) => {
    onChange?.(field?.value, newValue && newValue);
    setValue(newValue);
    field.onChange(newValue?.label || '');
  };

  return (
    <Stack>
      {label && (
        <Label type="field" required={required}>
          {label}
        </Label>
      )}
      <SelectCreateable
        {...rest}
        value={value}
        options={options}
        placeholder={placeholder}
        disabled={props.disabled || !context.editable}
        uniqueId={props.name}
        onChange={onChangeItem}
        isError={!!fieldState.error}
        noDataMessage={props.noDataMessage}
        onInputChange={(newValue, { action }) => {
          if (action !== 'input-blur' && action !== 'menu-close') {
            setInputValue(newValue);
            field.onChange(newValue);
          }
        }}
        inputValue={inputValue}
      />
      {fieldState.error && (
        <ErrorMessage css={{ mt: 4 }}>
          <AlertTriangle color={color.error} size={16} />
          {fieldState.error.message?.toString()}
        </ErrorMessage>
      )}
      {hint && (
        <Hint variant={state} css={{ mt: 4 }}>
          {state === 'success' ? (
            <CheckCircle color={color.success} size={16} />
          ) : (
            <Info color={color.info} size={16} />
          )}
          {hint}
        </Hint>
      )}
    </Stack>
  );
}

function SelectField(props: SelectProps) {
  const { disabled, label, required, hint, onChange, onAfterChange, ...rest } =
    props;
  const { control } = useFormContext<any>();
  const context = React.useContext(FormContext);

  const { field, fieldState } = useController({
    name: props.name,
    control,
  });

  const [state, setState] = React.useState<
    'default' | 'error' | 'focus' | 'success' | 'disabled'
  >('default');

  React.useEffect(() => {
    if (disabled) {
      setState('disabled');
    } else {
      setState('default');
    }
  }, [disabled]);

  React.useEffect(() => {
    if (fieldState.error) {
      setState('error');
    } else {
      setState('default');
    }
  }, [fieldState]);

  return (
    <Stack>
      {label && (
        <Label type="field" required={required}>
          {label}
        </Label>
      )}
      <Select
        {...rest}
        {...field}
        disabled={props.disabled || !context.editable}
        value={
          rest.options?.find((curr: any) => curr.value === field.value) || ''
        }
        uniqueId={props.name}
        onChange={(newValue, actionMeta) => {
          onChange?.(field.value, newValue && newValue.value);
          if (newValue) {
            field.onChange(newValue.value);
          } else {
            field.onChange(null);
          }
        }}
        isError={!!fieldState.error}
        noDataMessage={props.noDataMessage}
      />
      {fieldState.error && (
        <ErrorMessage css={{ mt: 4 }}>
          <AlertTriangle color={color.error} size={16} />
          {fieldState.error.message?.toString()}
        </ErrorMessage>
      )}
      {hint && (
        <Hint variant={state} css={{ mt: 4 }}>
          {state === 'success' ? (
            <CheckCircle color={color.success} size={16} />
          ) : (
            <Info color={color.info} size={16} />
          )}
          {hint}
        </Hint>
      )}
    </Stack>
  );
}

function StepperSelectField(props: StepperSelectProps) {
  const { disabled, ...rest } = props;
  const context = React.useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  return (
    <StepperSelect
      {...rest}
      onChange={(value) => field.onChange(value)}
      value={field.value}
      disabled={!context.editable || disabled}
      errorMessage={
        fieldState.error ? fieldState.error.message?.toString() : undefined
      }
    />
  );
}

function DropdownSelectField(props: DropdownSelectProps) {
  const { disabled, options, ...rest } = props;
  const context = React.useContext(FormContext);
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({
    control,
    name: props.name,
  });

  return (
    <DropdownSelect
      {...rest}
      {...field}
      onChange={(value) => field.onChange(value)}
      value={field.value}
      options={options}
      disabled={!context.editable || disabled}
      errorMessage={
        fieldState.error ? fieldState.error.message?.toString() : undefined
      }
      onAfterChange={props.onAfterChange}
    />
  );
}

function MultipleSelectField(props: MultipleSelectProps) {
  const { disabled, ...rest } = props;
  const context = React.useContext(FormContext);
  const { setValue, watch, formState } = useFormContext<any>();
  const error = getFieldError(props.name, formState.errors)?.message;

  return (
    <MultipleSelect
      {...rest}
      disabled={!context.editable || disabled}
      errorMessage={error ? error.toString() : undefined}
      onChange={(value) => {
        const temp: string[] = [];
        value.map((val) => {
          val.status && temp.push(val.value);
        });
        setValue(props.name, temp, { shouldValidate: true, shouldTouch: true });
      }}
      value={(rest.options || []).map((option) => {
        const findValue = (watch(props.name) || []).find(
          (curr) => curr === option.value,
        );
        if (findValue) {
          return { ...option, status: true };
        }
        return option;
      })}
    />
  );
}

function StepperField(props: StepperProps) {
  const { disabled, onChange, ...rest } = props;
  const context = React.useContext(FormContext);
  const { setValue, watch } = useFormContext<any>();

  return (
    <Stepper
      {...rest}
      disabled={!context.editable || disabled}
      value={watch(props.name)}
      onChange={(val) => {
        setValue(props.name, val, { shouldValidate: true });
        onChange && onChange(val);
      }}
    />
  );
}

export default function Input(
  props:
    | SubmitProps
    | TextInputProps
    | MaskedInputProps
    | TextareaInputProps
    | CheckBoxProps
    | RadioGroupProps
    | SubmitProps
    | OTPProps
    | SelectProps
    | MultipleFilePickerProps
    | StepperSelectProps
    | DropdownSelectProps
    | PhoneTextInputProps
    | MultipleSelectProps
    | StepperProps
    | DatePickerProps
    | SelectCreateableProps,
) {
  switch (props.type) {
    case 'select-createable':
      return <SelectCreateableField {...props} />;
    case 'checkbox':
      return <CheckboxField {...props} />;
    case 'radio-group':
      return <RadioGroupInputField {...props} />;
    case 'submit':
    case 'button':
      return <SubmitField {...props} />;
    case 'otp':
      return <OTPInput {...props} />;
    case 'date':
      return <DatePickerInputField {...props} />;
    case 'textarea':
      return <TextareaInputField {...props} />;
    case 'select':
      return <SelectField {...props} />;
    case 'files':
      return <MultipleFilePicker {...props} />;
    case 'dropdown-select':
      return <DropdownSelectField {...props} />;
    case 'stepper-select':
      return <StepperSelectField {...props} />;
    case 'phone':
      return <PhoneTextInputField {...props} />;
    case 'multiple-select':
      return <MultipleSelectField {...props} />;
    case 'stepper':
      return <StepperField {...props} />;
    case 'masked-text':
      return <MaskedTextInputField {...props} />;
    default:
      return <TextInputField {...props} />;
  }
}
