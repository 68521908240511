import * as React from 'react';
import { CSS, styled, VariantProps } from 'styles';
import typography from 'styles/typography';

const StyledLabel = styled('span', {
  ...typography.buttonSmall,
  userSelect: 'none',
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 5,
  boxSizing: 'border-box',
  boxShadow: 'none',
  color: '$neutral',
  variants: {
    type: {
      field: {
        ...typography.body3,
        color: '$typeDisabled',
        mb: 4,
      },
    },
    required: {
      true: {
        '&::after': {
          content: ' *',
          color: '$error',
          paddingRight: 12,
        },
      },
    },
    disabled: {
      true: {
        color: '$neutral4',
      },
    },
    readOnly: {
      true: {
        color: '$neutral4',
      },
    },
  },
});

type LabelProps = React.HTMLAttributes<HTMLSpanElement> & {
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

type CssProps = { css?: CSS };

type LabelVariants = VariantProps<typeof StyledLabel>;
export type ILabelProps = LabelProps & CssProps & LabelVariants;

const Label = React.forwardRef<HTMLSpanElement, ILabelProps>((props, ref) => {
  const { ...rest } = props;
  return <StyledLabel ref={ref} {...rest} />;
});

Label.displayName = 'Label';

export default Label;
