import { Popper } from 'components/commons/popper';
import Text from 'components/commons/text';
import Stepper from 'components/widgets/stepper/stepper';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { Div, Divider, StepperItem } from './style';
import Button from '../button';
import TextField from '../text-field';

export interface StepperSelectOption {
  label?: string;
  caption?: string;
  qty?: number;
  value?: string;
  id?: string;
  max?: number;
  [x: string]: any;
}

export interface IStepperSelectProps {
  options?: StepperSelectOption[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: StepperSelectOption[]) => void;
  label?: string;
  hint?: string;
  errorMessage?: string;
  onRenderLeftComponent?: React.ReactNode;
  placeholder?: string;
  value?: StepperSelectOption[];
  onBlur?: (blur: boolean) => void;
  onAfterChange?: (params: StepperSelectOption) => void;
}

export default function StepperSelect(props: IStepperSelectProps) {
  const {
    options = [],
    onChange,
    required,
    disabled,
    label,
    hint,
    errorMessage,
    onRenderLeftComponent,
    placeholder,
  } = props;

  const { t } = useTranslation();

  const initValues = React.useMemo(() => {
    return options.map((option) => {
      const curr = (props.value || []).find((val) => {
        return val.value === option.value;
      }, []);

      return {
        ...option,
        qty: curr?.qty ?? 0,
      };
    }, []);
  }, [options, props.value]);

  const [state, setState] = React.useState<StepperSelectOption[]>(initValues);

  const valueText = React.useMemo(() => {
    const count = initValues.reduce((prev, curr) => prev + (curr.qty || 0), 0);
    if (count) {
      const list = initValues.filter((curr) => !!curr.qty);
      return list
        .map((item) => {
          return `${t(`common:${item.value}`)}(x${item.qty})`;
        })
        .join(', ');
    }
    return '';
  }, [initValues, t]);

  return (
    <Popper
      css={{
        '& > div': {
          position: 'relative',
        },
      }}
      childContainerCss={{
        width: '100%',
        justifyContent: 'center',
        padding: 24,
        maxHeight: 400,
        overflowY: 'auto',
      }}
      outsideClick={() => {
        setState(initValues);
        if (props.onBlur) props.onBlur(false);
      }}
      trigger={(ref, toggle, isOpen) => (
        <TextField
          type="text"
          value={valueText}
          onClick={() => {
            toggle();
            if (isOpen && props.onBlur) props.onBlur(true);
          }}
          ref={ref}
          required={required}
          disabled={disabled}
          readOnly
          label={label}
          hint={hint}
          errorMessage={errorMessage}
          placeholder={placeholder}
          onRenderLeftComponent={onRenderLeftComponent}
        />
      )}
    >
      {(toggle) => (
        <>
          {initValues.map((option, idx) => (
            <>
              <StepperItem
                css={{ mb: options && options.length - 1 !== idx ? 0 : 32 }}
                key={option.value || option.id}
              >
                <Div>
                  <Text variant="body2" css={{ fontWeight: 600 }}>
                    {props?.options?.[idx]?.label}
                  </Text>
                  <Text variant="body4" css={{ color: '$typeGray' }}>
                    {props?.options?.[idx]?.caption}
                  </Text>
                </Div>
                <Stepper
                  value={option.qty}
                  onChange={(value) =>
                    setState((prev) =>
                      prev.map((item) => {
                        if (item.label === option.label) {
                          return { ...item, qty: value };
                        }
                        return item;
                      }),
                    )
                  }
                  maxValue={option.max}
                />
              </StepperItem>
              {options && options.length - 1 !== idx && <Divider />}
            </>
          ))}
          <div>
            <Button
              onClick={() => {
                onChange && onChange(state);
                if (props.onBlur) props.onBlur(false);
                toggle();
              }}
              css={{ width: '100%' }}
            >
              {t('common:done')}
            </Button>
          </div>
        </>
      )}
    </Popper>
  );
}
