import { useRadioGroup } from '@react-aria/radio';
import { useRadioGroupState } from '@react-stately/radio';
import React from 'react';
import { styled } from 'styles';

import { RadioContext } from './context';
import { useDOMRef } from '../utils';

function RadioGroupLabel(prop) {
  const { label, labelProps } = prop;

  if (!label) return null;

  if (typeof label === 'function') return label({ labelProps });

  return <span {...labelProps}>{label}</span>;
}

function RadioGroup(props, ref) {
  const { children, label, customLabel, ...otherProps } = props;

  const domRef = useDOMRef(ref);

  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps } = useRadioGroup(props, state);

  return (
    <Container {...radioGroupProps} ref={domRef} {...otherProps}>
      <RadioGroupLabel labelProps={labelProps} label={label} />
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
    </Container>
  );
}

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const _RadioGroup = React.forwardRef(RadioGroup);
export { _RadioGroup as RadioGroup };
