import * as React from 'react';

const BoxSvgComponent = (props: any) => {
  const { size = 16, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x={0.545}
        y={0.545}
        width={14.91}
        height={14.91}
        rx={1.982}
        stroke="#A5A6AB"
        strokeWidth={1.09}
      />
    </svg>
  );
};

export default BoxSvgComponent;
