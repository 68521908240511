import IconBox from 'components/commons/icon-box';
import * as React from 'react';
import * as Icons from 'react-feather';
import { styled } from 'styles';
import color from 'styles/color';
import typography from 'styles/typography';

const StyledStepperContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& > *:not(:first-child)': {
    ml: 16,
  },
});

const StyledText = styled('span', {
  ...typography.body2,
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  minWidth: 25,
});

export type IStepperProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  'onChange'
> & {
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  onChange?: (value: number) => void;
  step?: number;
  value?: number;
};

const Stepper = React.forwardRef<HTMLDivElement, IStepperProps>(
  (props, ref) => {
    const {
      disabled,
      maxValue,
      minValue = 0,
      onChange,
      step = 1,
      value,
      ...rest
    } = props;
    const [counter, setCounter] = React.useState(value || minValue);

    const onChangeMinus = React.useCallback(() => {
      setCounter((prev) => {
        if (prev - step <= minValue) {
          return minValue;
        }
        return prev - step;
      });
    }, [step, minValue]);

    const onChangePlus = React.useCallback(() => {
      setCounter((prev) => {
        if (maxValue && prev + step >= maxValue) {
          return maxValue;
        }
        return prev + step;
      });
    }, [step, maxValue]);

    React.useEffect(() => {
      if (onChange) {
        onChange(counter);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    return (
      <StyledStepperContainer {...rest} ref={ref}>
        <IconBox
          disabled={disabled || counter <= minValue}
          onClick={onChangeMinus}
        >
          <Icons.MinusCircle
            color={
              disabled || counter <= minValue ? color.typeGray : color.primary
            }
          />
        </IconBox>
        <StyledText>{counter}</StyledText>
        <IconBox
          disabled={disabled || (!!maxValue && counter >= maxValue)}
          onClick={onChangePlus}
        >
          <Icons.PlusCircle
            color={
              disabled || (maxValue && counter >= maxValue)
                ? color.typeGray
                : color.primary
            }
          />
        </IconBox>
      </StyledStepperContainer>
    );
  },
);

Stepper.displayName = 'Stepper';

export default Stepper;
