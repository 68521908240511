import { styled } from 'styles';

export const StepperItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

export const Div = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const Divider = styled('div', {
  height: 1,
  width: '100%',
  backgroundColor: '$dbGray1',
  my: 16,
});
