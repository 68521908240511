import { useFocusRing } from '@react-aria/focus';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import Text from 'components/commons/text';
import * as React from 'react';
import { useCheckbox } from 'react-aria';
import { CSS, styled } from 'styles';
import color from 'styles/color';

import {
  CheckedBoxSvgComponent,
  BoxSvgComponent,
  IndeterminateSvgComponent,
} from './assets';

const Label = styled('label', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  userSelect: 'none',
  width: 'fit-content',
  borderRadius: 4,
  padding: 2,
  variants: {
    focused: {
      true: {
        outlineColor: '#DCD4FF',
        outlineStyle: 'solid',
        outlineWidth: 'medium',
      },
    },
    disabled: {
      true: {
        opacity: 0.6,
      },
    },
  },
});

const RequiredStar = styled('span', {
  color: '$error',
});

const StyledCheckboxContainer = styled('div', {
  marginRight: 8,
  display: 'flex',
  alignItems: 'center',
});

export type ICheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  defaultSelected?: boolean;
  isSelected?: boolean;
  onChange?: (isSelected: boolean) => void;
  value?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isIndeterminate?: boolean;
  autoFocus?: boolean;
  label?: React.ReactNode;
  labelClassName?: string;
  labelCss?: CSS;
  // validationState: 'valid' | 'invalid';
};

const Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>(
  (props, ref) => {
    const state = useToggleState(props);
    const fallbackRef = React.useRef<HTMLInputElement>(null);
    const domRef = ref || fallbackRef;
    //@ts-ignore
    const { inputProps } = useCheckbox(props, state, domRef);
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <Label
        focused={isFocusVisible}
        disabled={props.isDisabled}
        className={props.labelClassName}
        css={props.labelCss}
      >
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} ref={domRef} />
        </VisuallyHidden>
        <StyledCheckboxContainer>
          {inputProps['aria-checked'] === 'mixed' ? (
            <IndeterminateSvgComponent />
          ) : state.isSelected ? (
            <CheckedBoxSvgComponent
              color={props.isDisabled ? color.disabled : color.primary}
            />
          ) : (
            <BoxSvgComponent />
          )}
        </StyledCheckboxContainer>
        <Text variant="body4">
          {props.label} {props.required && <RequiredStar>*</RequiredStar>}
        </Text>
      </Label>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
