import * as React from 'react';
import colors from 'styles/color';

const CheckedBoxSvgComponent = (props: any) => {
  const { size = 16, color = colors.primary2, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.527 0A2.527 2.527 0 0 0 0 2.527v10.946A2.527 2.527 0 0 0 2.527 16h10.946A2.527 2.527 0 0 0 16 13.473V2.527A2.527 2.527 0 0 0 13.473 0H2.527Zm9.837 4.963a.75.75 0 1 0-1.213-.882L7.053 9.716 5.043 7.78a.75.75 0 0 0-1.041 1.081l2.218 2.134a1.25 1.25 0 0 0 1.877-.165l4.267-5.867Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckedBoxSvgComponent;
