import { useRef, useImperativeHandle } from 'react';

export function createDOMRef(ref) {
  return {
    UNSAFE_getDOMNode() {
      return ref.current;
    },
  };
}

export function createFocusableRef(domRef, focusableRef = domRef) {
  return {
    ...createDOMRef(domRef),

    focus() {
      if (focusableRef.current) {
        focusableRef.current.focus();
      }
    },
  };
}

export function useDOMRef(ref) {
  const domRef = useRef(null);
  useImperativeHandle(ref, () => createDOMRef(domRef));

  return domRef;
}

export function useFocusableRef(ref, focusableRef) {
  const domRef = useRef(null);
  useImperativeHandle(ref, () => createFocusableRef(domRef, focusableRef));

  return domRef;
}
