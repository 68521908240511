import { useFocusRing } from '@react-aria/focus';
import { useRadio } from '@react-aria/radio';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import React, { forwardRef, useRef } from 'react';
import { styled } from 'styles';

import { useRadioProvider } from './context';
import { useFocusableRef } from '../utils';

function Radio(props, ref) {
  const { children, customIcon, ...otherProps } = props;

  const inputRef = useRef(null);
  const domRef = useFocusableRef(ref, inputRef);

  const { isFocusVisible, focusProps } = useFocusRing(props);

  const state = useRadioProvider();
  const { inputProps } = useRadio(props, state, inputRef);

  const isSelected = state.selectedValue === props.value;

  return (
    <Label ref={domRef} {...otherProps}>
      {customIcon ? (
        <VisuallyHidden>
          <input {...inputProps} {...focusProps} />
        </VisuallyHidden>
      ) : (
        <input {...inputProps} ref={inputRef} />
      )}
      {typeof customIcon === 'function'
        ? customIcon({ isFocusVisible, isSelected })
        : customIcon}
      <Children>{children}</Children>
    </Label>
  );
}

const Label = styled('label', {
  display: 'flex',
  alignItems: 'center',
  '& input': {
    margin: 0,
  },
});

const Children = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  ml: 16,
});

const _Radio = forwardRef(Radio);
export { _Radio as Radio };
