import * as React from 'react';
import colors from 'styles/color';

const IndeterminateSvgComponent = (props: any) => {
  const { size = 16, color = colors.primary2, ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.222 0H1.778C.8 0 0 .8 0 1.778v12.444C0 15.2.8 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0Zm-2.666 8.889H4.444A.892.892 0 0 1 3.556 8c0-.489.4-.889.888-.889h7.112c.488 0 .888.4.888.889s-.4.889-.888.889Z"
        fill={color}
      />
    </svg>
  );
};
export default IndeterminateSvgComponent;
