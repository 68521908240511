import { Popper } from 'components/commons/popper';
import Text from 'components/commons/text';
import * as React from 'react';

import { Div, Divider, StepperItem } from './style';
import TextField from '../text-field';

export interface StepperSelectOption {
  label?: string;
  caption?: string;
  value?: string;
  id?: string;
}

export interface IStepperSelectProps {
  options?: StepperSelectOption[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: StepperSelectOption) => void;
  label?: string;
  hint?: string;
  errorMessage?: string;
  onRenderLeftComponent?: React.ReactNode;
  placeholder?: string;
  value?: StepperSelectOption;
  onBlur?: (blur: boolean) => void;
  onAfterChange?: (params: StepperSelectOption) => void;
}

export default function DropdownSelect(props: IStepperSelectProps) {
  const [state, setState] = React.useState<StepperSelectOption | undefined>(
    props.value || undefined,
  );

  const {
    options,
    required,
    disabled,
    label,
    hint,
    errorMessage,
    onRenderLeftComponent,
    placeholder,
    onChange,
  } = props;

  //sync props with state
  React.useEffect(() => {
    setState(props.value);
  }, [props.value]);

  return (
    <Popper
      css={{
        '& > div': {
          position: 'relative',
        },
      }}
      childContainerCss={{
        width: '100%',
        justifyContent: 'center',
        maxHeight: 400,
        overflowY: 'auto',
      }}
      outsideClick={() => {
        setState(state);
        if (props.onBlur) props.onBlur(false);
      }}
      trigger={(ref, toggle, isOpen) => (
        <TextField
          type="text"
          value={state?.label || ''}
          onClick={() => {
            toggle();
            if (isOpen && props.onBlur) props.onBlur(true);
          }}
          ref={ref}
          required={required}
          disabled={disabled}
          readOnly
          label={label}
          hint={hint}
          errorMessage={errorMessage}
          placeholder={placeholder}
          onRenderLeftComponent={onRenderLeftComponent}
        />
      )}
    >
      {(toggle) => (
        <>
          {(options || []).map((option, idx) => (
            <>
              <StepperItem
                key={option.id || option.value}
                css={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setState(option);
                  onChange && onChange(option);
                  props.onAfterChange?.(option);
                  toggle();
                }}
                variant={state?.value === option.value ? 'active' : 'normal'}
              >
                <Div>
                  <Text variant="body2" css={{ fontWeight: 600 }}>
                    {option.label}
                  </Text>
                  <Text variant="body4" css={{ colonChangeor: '$typeGray' }}>
                    {option.caption}
                  </Text>
                </Div>
              </StepperItem>
              {idx + 1 !== options?.length && <Divider />}
            </>
          ))}
        </>
      )}
    </Popper>
  );
}
