import { Popper } from 'components/commons/popper';
import Button from 'components/elements/button';
import Checkbox from 'components/elements/checkbox/checkbox';
import { Divider, StepperItem } from 'components/elements/stepper-select/style';
import TextField from 'components/elements/text-field';
import unionBy from 'lodash/unionBy';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

export interface MultipleSelectOption {
  label: string;
  status?: boolean;
  value: string;
  group?: string;
  disable?: boolean;
}

export interface IMultipleSelectProps {
  options?: MultipleSelectOption[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: MultipleSelectOption[]) => void;
  label?: string;
  hint?: string;
  errorMessage?: string;
  onRenderLeftComponent?: React.ReactNode;
  placeholder?: string;
  value?: MultipleSelectOption[];
  onBlur?: (blur: boolean) => void;
  limited?: number;
  limitedGroup?: { [x: string]: number };
}

export default function MultipleSelect(props: IMultipleSelectProps) {
  const { t } = useTranslation();
  const [state, setState] = React.useState<MultipleSelectOption[]>([]);
  const initValues = unionBy(props.value, props.options, 'label');
  const valueText = React.useMemo(() => {
    return initValues
      .filter((curr) => curr.status)
      .map((item) => {
        return `${item.label}`;
      })
      .join(', ');
  }, [initValues]);

  const {
    options,
    onChange,
    required,
    disabled,
    label,
    hint,
    errorMessage,
    onRenderLeftComponent,
    placeholder,
    limited,
    limitedGroup,
  } = props;

  return (
    <Popper
      css={{
        '& > div': {
          position: 'relative',
        },
      }}
      childContainerCss={{
        width: '100%',
        justifyContent: 'center',
        padding: 24,
        maxHeight: 400,
        overflowY: 'auto',
      }}
      outsideClick={() => {
        setState(initValues);
        if (props.onBlur) props.onBlur(false);
      }}
      trigger={(ref, toggle, isOpen) => (
        <TextField
          type="text"
          value={valueText}
          onClick={() => {
            toggle();
            setState(initValues);
            if (isOpen && props.onBlur) props.onBlur(true);
          }}
          ref={ref}
          required={required}
          disabled={disabled}
          readOnly
          label={label}
          hint={hint}
          errorMessage={errorMessage}
          placeholder={placeholder}
          onRenderLeftComponent={onRenderLeftComponent}
        />
      )}
    >
      {(toggle) => (
        <>
          {state.map((option, idx) => {
            return (
              <>
                <StepperItem
                  css={{ mb: options && options.length - 1 !== idx ? 0 : 32 }}
                  key={option.value}
                >
                  <Checkbox
                    label={option.label}
                    isSelected={option.status}
                    onChange={(checkStatus) => {
                      setState((prev) =>
                        prev.map((item) => {
                          if (item.value === option.value) {
                            return { ...item, status: checkStatus };
                          }
                          return item;
                        }),
                      );
                    }}
                    isDisabled={
                      option.disable ||
                      (limited
                        ? state.filter((value) => value.status).length >=
                            limited && !option.status
                        : //disable (true)
                        limitedGroup && option.group
                        ? //disable (true)
                          limitedGroup[option.group] ===
                            state.filter(
                              (value) =>
                                value.status && value.group === option.group,
                            ).length && !option.status
                        : false)
                    }
                  />
                </StepperItem>
                {options && options.length - 1 !== idx && <Divider />}
              </>
            );
          })}
          <Button
            onClick={() => {
              onChange && onChange(state);
              if (props.onBlur) props.onBlur(false);
              toggle();
            }}
            css={{ width: '100%' }}
          >
            {t('common:done')}
          </Button>
        </>
      )}
    </Popper>
  );
}
