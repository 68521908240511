import produce from 'immer';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import MainFilePicker from './main-file-picker';
import PreviewPicker from './preview-picker';
import {
  MainPreviewContainer,
  PickerContainer,
  PreviewSeparator,
} from './styles';
import { InputProps } from '../field-type';
import { FormContext } from '../form';
import Label from '../label';

export interface MultipleFilePickerProps extends InputProps {
  type: 'files';
  valueKey: string;
  previewKey: string;
  label?: string;
  disabled?: boolean;
  noErrorText?: boolean;
  maxWidth?: string;
  resizeImage?: boolean;
  resizeWidth?: number;
  resizeHeight?: number;
  required?: boolean;
}

export default function MultipleFilePicker(props: MultipleFilePickerProps) {
  const {
    valueKey,
    previewKey,
    resizeImage,
    resizeWidth,
    resizeHeight,
    maxWidth = '500px',
    required,
    label,
  } = props;
  const { control } = useFormContext<any>();

  const context = React.useContext(FormContext);
  const disabled = props.disabled || !context.editable;

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field, fieldState, formState }) => {
        const handleDeleteFile = (index) => {
          const newFiles = produce(field.value, (draft) => {
            draft.splice(index, 1);
          });
          field.onChange(newFiles);
        };

        const handleAddOrUpdateFile = (
          { file, fileUrl }: { file: string; fileUrl: string },
          index?: number,
        ) => {
          const newFiles = produce(field.value, (draft) => {
            if (index !== undefined) {
              draft[index][valueKey] = file;
              draft[index][previewKey] = fileUrl;
            } else {
              draft.push({
                [valueKey]: file,
                [previewKey]: fileUrl,
              });
            }
          });
          field.onChange(newFiles);
        };

        const _onPicked = (file: string, fileUrl: string, idx: number) => {
          handleAddOrUpdateFile({ file, fileUrl }, idx);
        };

        return (
          <>
            {label && (
              <Label type="field" required={required}>
                {label}
              </Label>
            )}
            <PickerContainer css={{ maxWidth, width: '100%' }}>
              {Array.isArray(field.value) && (
                <MainPreviewContainer>
                  {field?.value?.map((item, idx) => {
                    return (
                      <>
                        <PreviewPicker
                          disabled={disabled}
                          onDelete={() => handleDeleteFile(idx)}
                          preview={item[props.previewKey]}
                          onPicked={(file, fileUrl) =>
                            _onPicked(file, fileUrl, idx)
                          }
                          resizeHeight={resizeHeight}
                          resizeImage={resizeImage}
                          resizeWidth={resizeWidth}
                        />
                        {idx % 4 !== 3 && <PreviewSeparator />}
                      </>
                    );
                  })}
                </MainPreviewContainer>
              )}
              <MainFilePicker
                disabled={disabled}
                onPicked={(file, fileUrl) =>
                  handleAddOrUpdateFile({
                    file,
                    fileUrl,
                  })
                }
                resizeHeight={resizeHeight}
                resizeImage={resizeImage}
                resizeWidth={resizeWidth}
              />
            </PickerContainer>
          </>
        );
      }}
    />
  );
}
